:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #191919; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #f6c324; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --contrast-color: #ffffff;
  --british-yellow: #f6c324;
  --british-blue: #002f9e;
}

.box-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fdfdfd;
  min-height: 170px; /* Ensures consistent height */
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.icon-container {
  flex-shrink: 0;
  width: 50px; /* Ensure consistent size for the icon container */
  height: 50px; /* Ensure consistent size for the icon container */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--british-blue); /* Light background for the icon */
}

.check-icon {
  color: var(--contrast-color);
  /* margin-right: 15px; */
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.box-container h5 {
  font-weight: 700;
  font-size: 18px;
}

.box-container p {
  font-size: 14px;
  color: #444;
}

.row.justify-content-center {
  justify-content: center;
}

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 33.33333%; /* 3 cards per row */
    max-width: 33.33333%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333%; /* 3 cards per row */
    max-width: 33.33333%;
  }
}

.visa-requirements-container {
  background-color: #f9f9f9;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.content-section {
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
}

.section-title {
  font-size: 2rem;
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.section-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--default-color);
  margin: 20px 0;
  padding-left: 15px;
  border-left: 4px solid var(--accent-color);
}

.section-highlight {
  font-size: 1.5rem; /* Slightly larger font for emphasis */
  font-weight: bold;
  color: var(--british-blue); /* Classic color for headings */
  padding: 20px 30px; /* Comfortable padding */
  border-radius: 8px; /* Rounded corners for a soft look */
  text-align: center; /* Center-aligned text */
  margin: 15px 0; /* Spacing above and below */
}

.section-highlight strong {
  display: block; /* Block display for emphasis */
  font-size: 1.2rem; /* Slightly larger font for strong text */
}
