/* Add padding and box shadow to the entire section */
.category-e-container {
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto; /* Centered the card */
  background-color: var(--contrast-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  border-radius: 8px; /* Rounded corners */
  border: none !important;
}

/* Styling for the heading */
.category-e-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px !important;
  color: #002f9e;
}

/* Styling for the subtitle */
.category-e-subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: #444444;
}

/* Styling for the card container */
.category-e-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 10px 0;
  position: relative;
}

/* Card styling with box shadow */
.category-e-card {
  position: relative;
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* Optional: Add hover effect for a stronger shadow */
.category-e-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Styling for the card number */
.category-e-card-number {
  position: absolute;
  top: -20px;
  left: -20px;
  font-size: 48px;
  font-weight: bold;
  color: #002f9e;
  padding: 10px;
  border-radius: 50%;
}

/* Additional padding inside the card */
.category-e-card-content {
  padding-left: 60px;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .category-e-cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .category-e-cards {
    grid-template-columns: 1fr;
  }
}
