/* General Section Styling */
.guarantee-section {
  background-color: #f9f9f9;
  padding: 4rem 0;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
}

.section-subtitle {
  color: #f6c324; /* British yellow */
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.section-heading {
  font-size: 2.5rem;
  color: #002f9e; /* British blue */
  font-weight: 700;
}

/* Introduction Text */
.intro-text {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
}

/* Guarantee Details */
.guarantee-details {
  background-color: #ffffff;
  padding: 2rem 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.guarantee-title {
  font-size: 1.8rem;
  color: #002f9e;
  font-weight: 700;
  margin-bottom: 1rem;
}

.guarantee-description {
  font-size: 1rem;
  line-height: 1.7;
  color: #555;
}

/* Guarantee List */
.guarantee-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.guarantee-item {
  margin-bottom: 1.5rem;
}

.guarantee-item-title {
  font-size: 1.3rem;
  color: #002f9e;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.nested-list,
.double-nested-list {
  list-style: none;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.nested-item,
.double-nested-item {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: #555;
}

.double-nested-item {
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
}

/* Custom Bullet Point */
.bullet {
  position: absolute;
  left: 0;
  font-size: 1.2rem;
  color: #f6c324; /* British yellow */
}

/* Terms and Conditions */
.terms-list {
  list-style-type: none;
  padding-left: 0;
}

.terms-item {
  margin-bottom: 1.5rem;
}

.terms-item-title {
  font-size: 1.3rem;
  color: #002f9e;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.terms-link {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: #002f9e;
}

.terms-link a {
  color: #f6c324; /* British yellow */
  text-decoration: underline;
  font-weight: 600;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .guarantee-details {
    padding: 1.5rem 1rem;
  }

  .section-heading {
    font-size: 2rem;
  }

  .guarantee-title {
    font-size: 1.6rem;
  }

  .intro-text {
    font-size: 1rem;
    line-height: 1.6;
  }

  .guarantee-description,
  .nested-item,
  .double-nested-item {
    font-size: 0.95rem;
  }
}
