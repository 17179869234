/* General container styling */
.relationship-requirements-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.relationship-requirements-container:hover {
  transform: translateY(-5px);
}

/* Title styling */
.relationship-requirements-title {
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem; /* Increase margin below header */
  text-align: center;
  width: 100%; /* Ensure full-width to align with content */
}

/* Content wrapper styling */
.relationship-requirements-content {
  width: 100%; /* Full width */
  display: flex;
  flex-direction: column; /* Stack intro and list in a column */
  align-items: center; /* Center the content horizontally */
}

/* Introduction paragraph */
.relationship-requirements-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 1000px; /* Increase max-width */
}

/* List wrapper styling */
.relationship-requirements-list {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center items horizontally */
  gap: 2rem; /* Gap between list items */
  width: 80%; /* Width of the list container */
  max-width: 1200px; /* Limit the maximum width */
}

/* Individual list item */
.relationship-requirements-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-left: 5px solid #002f9e; /* Custom left border for visual appeal */
  transition: background-color 0.3s ease;
  width: calc(50% - 2rem); /* 50% width with gap adjustments */
  max-width: 500px; /* Limit the maximum width of each item */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* List styling inside list items */
.relationship-requirements-item ul {
  margin-top: 0.5rem;
  padding-left: 0;
  color: #555;
  list-style-type: none; /* Remove default bullets */
  font-size: 1rem;
  text-align: left; /* Align the list items text */
}

/* Custom bullet styling */
.relationship-requirements-item ul li {
  margin-bottom: 0.75rem;
  padding-left: 0;
  position: relative;
}

/* Custom bullet styling */
.relationship-requirements-item ul li:before {
  font-size: 1.2rem;
  position: absolute;
  left: -1.5rem; /* Adjust position to fit with the list item */
  top: 0;
}

/* Icon styling */
.relationship-icon {
  font-size: 2rem;
  color: #f6c324; /* British yellow */
  margin-bottom: 1rem; /* Space between icon and text */
}

/* Text within list items */
.relationship-requirements-item strong {
  font-weight: 600;
  font-size: 1.2rem;
  color: #191919; /* Heading color */
  text-align: left; /* Align the strong tag text */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .relationship-requirements-list {
    flex-direction: column; /* Stack items vertically on smaller screens */
    width: 90%; /* Adjust width for smaller screens */
  }

  .relationship-requirements-item {
    width: 100%; /* Make each item take full width on smaller screens */
    max-width: none; /* Remove max-width restriction */
  }
}
