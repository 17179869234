/* General Container */
.category-g-container {
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto; /* Centered the card */
  background-color: var(--contrast-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  border-radius: 8px; /* Rounded corners */
  border: none !important;
}

/* Title Styling */
.category-g-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px !important;
  color: #002f9e;
}

/* Subtitle Styling */
.category-g-subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
  color: #444444;
}

/* Card Layout */
.category-g-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2-column grid */
  gap: 20px;
  padding: 10px 0;
  z-index: 15;
  position: relative;
}

/* Individual Card Styling */
.category-g-card {
  position: relative;
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  z-index: 20;
}

/* Hover Effect */
.category-g-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Card Number Styling */
.category-g-card-number {
  position: absolute;
  top: -20px;
  left: -20px;
  font-size: 48px;
  font-weight: bold;
  color: #002f9e;
  padding: 10px;
  border-radius: 50%;
  z-index: 25;
}

/* Card Content Padding */
.category-g-card-content {
  padding-left: 60px;
}

/* Combining Income Section */
.category-g-combine-title {
  font-size: 22px;
  font-weight: bold;
  color: #002f9e;
}

.category-g-combine-subtitle {
  font-size: 16px;
  color: #444444;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .category-g-cards {
    grid-template-columns: 1fr; /* Stacks cards in one column on smaller screens */
  }
}
