/* General container styling */
.summary-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

/* Title styling */
.summary-title {
  text-align: center;
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem; /* Large font size for the title */
  margin-bottom: 1.5rem; /* Space below the title */
  width: 100%; /* Full-width to align with content */
}

/* Content styling */
.summary-content {
  font-size: 1.1rem; /* Slightly larger text for readability */
  color: #444; /* Darker text color for contrast */
  line-height: 1.6; /* Increased line height for readability */
  max-width: 800px; /* Limit width for better alignment */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .summary-container {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }

  .summary-title {
    font-size: 1.5rem; /* Adjust title font size for smaller screens */
  }

  .summary-content {
    font-size: 1rem; /* Adjust content font size for smaller screens */
  }
}
