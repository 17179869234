/* General container styling */
.immigration-status-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.immigration-status-container:hover {
  transform: translateY(-5px);
}

/* Title styling */
.immigration-status-title {
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}

/* Content wrapper styling */
.immigration-status-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Introduction paragraph */
.immigration-status-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 1000px;
}

/* List wrapper styling */
.immigration-status-list {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Three columns layout for non-responsive view */
  gap: 2rem; /* Gap between grid items */
  width: 80%; /* Increase the width of the list container */
  max-width: 1200px; /* Limit the maximum width of the list container */
  justify-content: center; /* Center the items when less than full row */
}

/* Individual list item */
.immigration-status-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-left: 5px solid #002f9e; /* Custom left border */
  transition: background-color 0.3s ease;
  max-width: 500px; /* Limit the width of each item */
  justify-self: center; /* Center the item in its row */
  width: 100%; /* Full width within its container */
}

/* List styling inside list items */
.immigration-status-item ul {
  margin-top: 0.5rem;
  padding-left: 0;
  color: #555;
  list-style-type: none;
  font-size: 1rem;
  text-align: left;
}

.immigration-status-item ul li {
  margin-bottom: 0.75rem;
  padding-left: 0;
  position: relative;
}

/* Icon styling */
.immigration-status-icon {
  font-size: 2rem;
  color: #f6c324; /* British yellow */
  margin-bottom: 1rem;
}

.immigration-status-item strong {
  font-weight: 600;
  font-size: 1.2rem;
  color: #191919;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .immigration-status-list {
    grid-template-columns: 1fr; /* Single column for small screens */
    width: 90%;
  }
}
