.accommodation-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.accommodation-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.accommodation-text {
  flex: 1;
}

.accommodation-title {
  color: #002f9e;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 4px solid #f6c324;
  display: inline-block;
}

.accommodation-description {
  color: #444444;
  font-size: 18px;
  line-height: 1.6;
}

.accommodation-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.accommodation-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.exceptional-container {
  margin-top: 40px;
}

.accommodation-exception-title {
  /* color: #002f9e; */
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  /* border-bottom: 4px solid #f6c324; */
  display: inline-block;
}

.exceptional-description,
.exceptional-documents {
  color: #444444;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.exceptional-documents {
  font-style: italic;
}

@media (max-width: 768px) {
  .accommodation-content {
    flex-direction: column;
  }

  .accommodation-image {
    margin-top: 20px;
  }
}
