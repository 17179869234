/* General container styling */
.english-language-requirements-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.english-language-requirements-container:hover {
  transform: translateY(-5px);
}

/* Title styling */
.english-language-requirements-title {
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem; /* Increase margin below header */
  text-align: center;
  width: 100%; /* Ensure full-width to align with content */
}

/* Content wrapper styling */
.english-language-requirements-content {
  width: 100%; /* Full width */
  display: flex;
  flex-direction: column; /* Stack intro and list in a column */
  align-items: center; /* Center the content horizontally */
}

/* Introduction paragraph */
.english-language-requirements-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 1000px; /* Increase max-width */
}

/* List wrapper styling */
.english-language-requirements-list {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Three columns layout for non-responsive view */
  gap: 2rem; /* Gap between grid items */
  width: 80%; /* Increase the width of the list container */
  max-width: 1200px; /* Limit the maximum width of the list container */
  justify-content: center; /* Center the items when less than full row */
}

/* Individual list item */
.english-language-requirements-item {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: flex-start; /* Align items to the start */
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-left: 5px solid #002f9e; /* Custom left border for visual appeal */
  transition: background-color 0.3s ease;
  width: 100%; /* Full width within its container */
  max-width: 500px; /* Limit the width of each item */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

/* List styling inside list items */
.english-language-requirements-item ul {
  margin-top: 0.5rem;
  padding-left: 0;
  color: #555;
  list-style-type: none; /* Remove default bullets */
  font-size: 1rem;
  text-align: left; /* Align the list items text */
}

/* Custom bullet styling */
.english-language-requirements-item ul li {
  margin-bottom: 0.75rem;
  padding-left: 0;
  position: relative;
}

/* Custom bullet styling */
.english-language-requirements-item ul li:before {
  font-size: 1.2rem;
  position: absolute;
  left: -1.5rem; /* Adjust position to fit with the list item */
  top: 0;
}

/* Icon styling */
.english-language-icon {
  font-size: 2rem;
  color: #f6c324; /* British yellow */
  margin-bottom: 1rem; /* Space between icon and text */
}

/* Text within list items */
.english-language-requirements-item strong {
  font-weight: 600;
  font-size: 1.2rem;
  color: #191919; /* Heading color */
  text-align: left; /* Align the strong tag text */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .english-language-requirements-list {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
    grid-template-rows: auto; /* Auto rows to fit content */
    width: 90%; /* Adjust width for smaller screens */
  }

  .english-language-requirements-item {
    width: 100%; /* Ensure items take full width */
  }
}

@media (max-width: 480px) {
  .english-language-requirements-title {
    font-size: 1.5rem; /* Smaller title font size for very small screens */
  }

  .english-language-requirements-intro {
    font-size: 1rem; /* Smaller subtitle font size for very small screens */
  }
}