:root {
  --background-color: #ffffff;
  --default-color: #444444;
  --heading-color: #191919;
  --accent-color: #f6c324;
  --contrast-color: #ffffff;
  --british-yellow: #f6c324;
  --british-blue: #002f9e;
}

/* General Section Styles */
section {
  margin: 10px;
  padding: 0;
  border: none;
}

/* Requirements Card Styles */
.requirements-card {
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto; /* Centered the card */
  background-color: var(--contrast-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  border-radius: 8px; /* Rounded corners */
}

.requirements-title {
  font-size: 2rem;
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 20px;
}

.requirements-info {
  margin-bottom: 20px;
}

.requirements-subtitle {
  font-size: 1.2rem;
  color: var(--heading-color);
  margin-bottom: 10px;
}

.requirements-text {
  font-size: 1rem;
  color: var(--default-color);
  margin: 10px 0;
  line-height: 1.6;
}

.requirements-list {
  list-style-type: disc;
  margin: 10px 0 10px 20px;
  padding: 0;
}

.requirements-list li {
  margin-bottom: 5px;
}

/* Financial Thresholds Section */
.financial-thresholds {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}

.threshold-header {
  text-align: center;
  margin-bottom: 20px;
}

.section-title {
  font-size: 2rem;
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 30px;
}

.threshold-description {
  font-size: 1.1rem;
  color: var(--british-blue);
}

.threshold-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.threshold-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.threshold-icon {
  flex-shrink: 0;
  margin-right: 15px;
}

.threshold-content {
  flex-grow: 1;
}

.threshold-subtitle {
  font-size: 1.2rem;
  color: var(--accent-color);
  margin-bottom: 5px;
}

.threshold-text {
  font-size: 1rem;
  color: var(--default-color);
}

/* Maintenance Requirement Section */
.maintenance-requirement {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 50px 20px;
}

.maintenance-header {
  text-align: center;
  margin-bottom: 20px;
}

.maintenance-title {
  font-size: 1.5rem;
  color: var(--heading-color);
  margin-bottom: 10px;
}

.maintenance-description {
  font-size: 1.1rem;
  color: var(--default-color);
}

.maintenance-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.maintenance-item {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.maintenance-icon {
  flex-shrink: 0;
  margin-right: 15px;
}

.maintenance-content {
  flex-grow: 1;
}

.maintenance-subtitle {
  font-size: 1.2rem;
  color: var(--accent-color);
  margin-bottom: 5px;
}

.maintenance-text {
  font-size: 1rem;
  color: var(--default-color);
}

/* Specified Benefits Section */
.specified-benefits {
  padding: 30px;
  border-radius: 12px;
  margin: 30px 20px;
}

.benefits-header {
  text-align: center;
  margin-bottom: 20px;
}

.benefits-title {
  font-size: 1.5rem;
  color: var(--heading-color);
  font-weight: 600;
  margin-bottom: 15px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Space between items */
}

.benefit-item {
  display: flex;
  align-items: center;
  background: var(--british-blue);
  color: var(--contrast-color);
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  flex-shrink: 0;
  margin-right: 12px;
  color: var(--accent-color); /* Set a distinct color for icons */
}

.benefit-text {
  font-size: 1rem;
  line-height: 1.5;
}

/* Income Sources Section */
.income-sources {
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}

.income-header {
  text-align: center;
  margin-bottom: 30px;
}

.income-title {
  font-size: 2rem;
  color: var(--heading-color);
  margin-bottom: 10px;
}

.income-intro {
  font-size: 1rem;
  color: var(--default-color);
  line-height: 1.6;
}

.income-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.income-item {
  display: flex;
  align-items: center;
  background-color: var(--contrast-color);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px); /* Responsive columns */
  max-width: calc(33.333% - 20px);
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.income-icon {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--british-blue);
  margin-right: 15px;
}

.income-category-title {
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 10px;
}

.income-description {
  color: var(--default-color);
}

/* Document Section */
/* Document Section */
.document-section {
  background-color: var(--background-color);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 170px;
  margin-bottom: 10px !important;
  margin-top: 60px;
}

.document-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.document-text {
  flex: 1;
  text-align: left;
  padding-right: 20px; /* Add some padding to the right */
}

.document-title {
  font-size: 1.5rem;
  color: var(--heading-color);
  font-weight: 700;
  margin-bottom: 10px;
}

.document-description {
  font-size: 1rem;
  color: var(--default-color);
}

.document-image {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}

.document-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .document-section {
    gap: 40px; /* Reduce gap for medium screens */
  }
}

@media (max-width: 768px) {
  .document-section {
    flex-direction: column; /* Stack content vertically */
    gap: 20px; /* Adjust gap for smaller screens */
    padding: 20px; /* Adjust padding */
  }
  .document-content {
    flex-direction: column; /* Stack text and image */
    align-items: center; /* Center align content */
  }
  .document-text {
    padding: 0 10px 20px; /* Add padding around text */
    text-align: center; /* Center-align text */
  }
  .document-image {
    justify-content: center; /* Center image */
    width: 100%; /* Full-width image on mobile */
  }
  .document-image img {
    max-width: 80%; /* Reduce image width */
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .income-item {
    flex: 1 1 calc(50% - 20px); /* Two items per row on medium screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .income-item {
    flex: 1 1 100%; /* One item per row on small screens */
    max-width: 100%;
  }
  .threshold-item,
  .maintenance-item,
  .benefit-item {
    flex-direction: column; /* Stack icon and content vertically */
    text-align: center; /* Center text */
    padding: 20px;
  }
  .threshold-icon,
  .maintenance-icon,
  .benefit-icon {
    margin-right: 0; /* Remove margin */
    margin-bottom: 15px; /* Add space below the icon */
  }
  .threshold-details,
  .maintenance-details {
    gap: 15px; /* Reduce gap for smaller screens */
  }
  .benefits-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}
