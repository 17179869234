/* General container styling */
.accommodation-requirements-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.accommodation-requirements-container:hover {
  transform: translateY(-5px);
}

/* Title styling */
.accommodation-requirements-title {
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}

/* Content wrapper styling */
.accommodation-requirements-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Introduction paragraph */
.accommodation-requirements-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 1000px;
}

/* List wrapper styling */
.accommodation-requirements-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 2rem;
  width: 80%;
  max-width: 1200px;
}

/* Individual list item */
.accommodation-requirements-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-left: 5px solid #002f9e;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 500px;
}

/* List styling inside list items */
.accommodation-requirements-item ul {
  margin-top: 0.5rem;
  padding-left: 0;
  color: #555;
  list-style-type: none;
  font-size: 1rem;
  text-align: left;
}

.accommodation-requirements-item ul li {
  margin-bottom: 0.75rem;
  padding-left: 0;
  position: relative;
}

.accommodation-requirements-item ul li:before {
  font-size: 1.2rem;
  position: absolute;
  left: -1.5rem;
  top: 0;
}

/* Text within list items */
.accommodation-requirements-item strong {
  font-weight: 600;
  font-size: 1.2rem;
  color: #191919;
  text-align: left;
}
/* Icon styling */
.accommodation-icon {
  font-size: 2rem;
  color: #f6c324; /* British yellow */
  margin-bottom: 1rem; /* Space between icon and text */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .accommodation-requirements-list {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 90%;
  }
}
