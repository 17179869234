/* Main Container Styling */
#category-a {
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto; /* Centered the card */
  background-color: var(--contrast-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  border-radius: 8px; /* Rounded corners */
  border: none !important;
}

/* Heading Styling */
.category-title {
  font-size: 28px; /* Adjust the font size for the heading */
  font-weight: bold;
  text-align: center; /* Center the heading */
  margin-bottom: 10px !important; /* Add more margin below the heading */
  color: #002f9e; /* Use the accent color for heading */
}

.category-subtitle {
  font-size: 18px;
  text-align: center; /* Center the subtitle */
  margin-bottom: 20px;
  color: #444444; /* Use a slightly darker color for subtitle */
}

/* Container for cards in 2-column and 2-row grid */
.category-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: auto auto; /* 2 rows */
  gap: 30px; /* Space between the cards */
  padding: 10px; /* Padding around the card area */
}

/* Card Styling */
.category-card {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Box shadow for each card */
  transition: box-shadow 0.3s ease; /* Smooth shadow transition on hover */
}

/* Hover Effect for Card */
.category-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Styling for the Card Number */
.card-number {
  position: absolute;
  top: -25px;
  left: -25px;
  font-size: 48px;
  font-weight: bold;
  color: #002f9e;
  padding: 15px;
  border-radius: 50%;
}

/* Padding Inside the Card */
.card-content {
  padding-left: 70px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .category-cards {
    grid-template-columns: 1fr; /* Single column layout for tablets */
    gap: 20px; /* Reduce space between cards */
  }

  .card-number {
    top: -20px;
    left: -20px;
    font-size: 40px; /* Adjust size for smaller screens */
    padding: 10px;
  }

  .category-card {
    padding: 25px; /* Adjust padding for smaller screens */
  }

  .card-content {
    padding-left: 60px; /* Adjust padding inside the card */
  }
}

@media (max-width: 768px) {
  #category-a {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .category-title {
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  .category-subtitle {
    font-size: 16px; /* Adjust font size for subtitle */
    margin-bottom: 15px; /* Adjust margin */
  }

  .category-cards {
    gap: 15px; /* Reduce gap between cards */
  }

  .category-card {
    padding: 20px; /* Adjust padding for mobile screens */
  }

  .card-number {
    top: -15px;
    left: -15px;
    font-size: 36px; /* Smaller size for mobile */
    padding: 8px;
  }

  .card-content {
    padding-left: 50px; /* Adjust padding for mobile */
  }
}

@media (max-width: 480px) {
  .category-title {
    font-size: 20px; /* Adjust font size for small mobile screens */
  }

  .category-subtitle {
    font-size: 14px; /* Adjust font size for subtitle */
  }

  .category-card {
    padding: 15px; /* Reduce padding for very small screens */
  }

  .card-number {
    font-size: 32px; /* Smaller size for very small screens */
    padding: 6px;
  }

  .card-content {
    padding-left: 40px; /* Adjust padding inside the card */
  }
}
