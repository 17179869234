/* General container styling */
.additional-requirements-container {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: none !important;
  margin: 50px auto;
  margin-top: 50px !important;
  position: relative;
  width: 100%;
  max-width: 1200px;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.additional-requirements-container:hover {
  transform: translateY(-5px);
}

/* Title styling */
.additional-requirements-title {
  color: #002f9e; /* British blue */
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 2rem; /* Increase margin below header */
  text-align: center;
  width: 100%; /* Ensure full-width to align with content */
}

/* Content wrapper styling */
.additional-requirements-content {
  width: 100%; /* Full width */
  display: flex;
  flex-direction: column; /* Stack intro and list in a column */
  align-items: center; /* Center the content horizontally */
}

/* Introduction paragraph */
.additional-requirements-intro {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 1000px; /* Increase max-width */
}

/* List wrapper styling */
.additional-requirements-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 2rem; /* Gap between grid items */
  width: 80%; /* Increase the width of the list container */
  max-width: 1200px; /* Limit the maximum width of the list container */
  justify-content: center; /* Center the items when less than full row */
}

/* Individual list item */
.additional-requirements-item {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: flex-start; /* Align items to the start */
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-left: 5px solid #002f9e; /* Custom left border for visual appeal */
  transition: background-color 0.3s ease;
  width: 100%; /* Full width within its container */
  max-width: 500px; /* Limit the width of each item */
  justify-self: center; /* Center single items in a row */
}

/* Icon styling */
.additional-requirements-icon {
  font-size: 2rem;
  color: #f6c324; /* British yellow */
  margin-bottom: 1rem; /* Space between icon and text */
}

/* List styling inside list items */
.additional-requirements-item ul {
  margin-top: 0.5rem;
  padding-left: 0;
  color: #555;
  list-style-type: none; /* Remove default bullets */
  font-size: 1rem;
  text-align: left; /* Align the list items text */
}

/* Custom bullet styling */
.additional-requirements-item ul li {
  margin-bottom: 0.75rem;
  padding-left: 0;
  position: relative;
}

/* Custom bullet styling */
.additional-requirements-item ul li:before {
  font-size: 1.2rem;
  position: absolute;
  left: -1.5rem; /* Adjust position to fit with the list item */
  top: 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .additional-requirements-list {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
    grid-template-rows: auto; /* Auto rows to fit content */
    width: 90%; /* Adjust width for smaller screens */
  }
}
